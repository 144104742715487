/* eslint-disable indent */
import * as cmk_table from "cmk_table";
import * as d3 from "d3";

// TODO: Use library functions from web/htdocs/js/modules/number_format.js
const NTOPNG_MIN_VISUAL_VALUE = 0.005;
export const ifid_dep = "ifid_dependent";

export class interface_table extends cmk_table.TableFigure {
    _host_address;
    _vlanid;
    _ifid;
    set_host_address(host_address) {
        this._host_address = host_address;
    }

    set_vlanid(vlanid) {
        this._vlanid = vlanid;
    }

    set_ifid(ifid) {
        this._ifid = ifid;
    }
    update_gui() {
        super.update_gui();
        this._div_selection.selectAll("select").on("change", event => {
            this._change_post_url(d3.select(event.target));
            this.scheduler.force_update();
        });
    }
    _change_post_url(select) {
        const name = select.property("name");
        const id = select.property("value");
        switch (name) {
            case "ntop_interface_choice":
                this.set_ifid(id);
                break;
            case "ntop_vlan_choice":
                this.set_vlanid(id);
                break;
            default:
                return;
        }
        const host_body = this._host_address
            ? "&host_address=" + this._host_address
            : "";
        this.set_post_url_and_body(
            "ajax_ntop_interface_quickstats.py?vlanid=" +
                this._vlanid +
                "&ifid=" +
                this._ifid +
                host_body
        );
        d3.selectAll("." + ifid_dep)
            .data()
            // @ts-ignore
            .forEach(o => o.set_ids(this._ifid, this._vlanid));
    }
}

export function bytes_to_volume(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Bytes";
    if (bytes > 0 && bytes < NTOPNG_MIN_VISUAL_VALUE)
        return "< " + NTOPNG_MIN_VISUAL_VALUE + " Bytes";
    const res = scale_value(bytes, sizes, 1024);
    return parseFloat(res[0]) + " " + res[1];
}

function scale_value(val, sizes, scale) {
    if (val == 0) return [0, sizes[0]];
    let i = Math.floor(Math.log(val) / Math.log(scale));
    if (i < 0 || isNaN(i)) {
        i = 0;
    } else if (i >= sizes.length) i = sizes.length - 1;

    return [Math.round((val / Math.pow(scale, i)) * 10) / 10, sizes[i]];
}

export function seconds_to_time(seconds) {
    if (seconds < 1) {
        return "< 1 sec";
    }

    let days = Math.floor(seconds / 86400);
    const hours = Math.floor(seconds / 3600 - days * 24);
    const minutes = Math.floor(seconds / 60 - days * 1440 - hours * 60);
    const sec = seconds % 60;
    let msg = "";
    const msg_array: string[] = [];

    if (days > 0) {
        const years = Math.floor(days / 365);

        if (years > 0) {
            days = days % 365;

            msg = years + " year";
            if (years > 1) {
                msg += "s";
            }

            msg_array.push(msg);
            msg = "";
        }
        msg = days + " day";
        if (days > 1) {
            msg += "s";
        }
        msg_array.push(msg);
        msg = "";
    }

    if (hours > 0) {
        if (hours < 10) msg = "0";
        msg += hours + ":";
    }

    if (minutes < 10) msg += "0";
    msg += minutes + ":";

    if (sec < 10) msg += "0";

    msg += sec;
    msg_array.push(msg);
    return msg_array.join(", ");
}
