import * as d3 from "d3";
import * as dc from "dc";
import crossfilter from "crossfilter2";
import * as cmk_tabs from "cmk_tabs";
import * as cmk_figures from "cmk_figures";
import * as ntop_utils from "ntop_utils";
import * as number_format from "number_format";

export class NtopAlertsTabBar extends cmk_tabs.TabsBar {
    constructor(div_selector) {
        super(div_selector);
    }

    _get_tab_entries() {
        return [EngagedAlertsTab, PastAlertsTab, FlowAlertsTab];
    }

    initialize(ifid: string) {
        cmk_tabs.TabsBar.prototype.initialize.call(this);
        this._activate_tab(this.get_tab_by_id("engaged_alerts_tab"));
        d3.selectAll<any, ABCAlertsPage>("." + ntop_utils.ifid_dep)
            .data()
            .forEach(o => o.set_ids(ifid));
    }

    _show_error_info(error_info) {
        console.log("data fetch error " + error_info);
    }
}

// Base class for all alert tables
class ABCAlertsPage extends cmk_figures.FigureBase<cmk_figures.FigureData> {
    _filter_choices;
    _crossfilter_time;
    _date_dimension;
    _hour_dimension;
    _table_details;
    _ifid;
    _vlanid;
    _fetch_filters;
    current_ntophost;
    _date_filter;
    _hour_filter;
    url_param = "";
    constructor(div_selector) {
        super(div_selector);
        this._filter_choices = [];
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    initialize(with_debugging) {
        this._crossfilter_time = crossfilter();

        // Date and hour filters
        this._date_dimension = this._crossfilter_time.dimension(d => d.date);
        this._hour_dimension = this._crossfilter_time.dimension(d => {
            return d.date.getHours() + d.date.getMinutes() / 60;
        });
        this._setup_time_based_filters(
            this._div_selection
                .append("div")
                .classed("time_filters " + this.page_id(), true)
        );

        // Fetch filters
        this._setup_fetch_filters(
            this._div_selection
                .append("div")
                .classed("fetch_filters " + this.page_id(), true)
        );

        const div_description = this._div_selection
            .append("div")
            .classed("description_filter " + this.page_id(), true);
        const div_status = this._div_selection
            .append("div")
            .classed("status " + this.page_id(), true);
        this._div_selection
            .append("div")
            .classed("details " + this.page_id(), true);

        // Table with details
        const cmk_dc_table_figure_class =
            cmk_figures.figure_registry.get_figure("dc_table");
        this._table_details = new cmk_dc_table_figure_class(
            "div.details." + this.page_id(),
            null
        );
        this._table_details.subscribe_data_pre_processor_hook(data =>
            this._convert_alert_details_to_dc_table_format(data)
        );

        this._table_details.subscribe_post_render_hook(() => {
            const label = this._div_selection
                .selectAll("div.status")
                .classed("loading_img_icon", false)
                .select("label");
            label.text(label.text().replace("Fetching", "Fetched"));
        });

        this._table_details.crossfilter(crossfilter());
        this._table_details.columns(this._get_columns());
        this._table_details.initialize();

        // Description filter
        this._setup_description_filter(div_description);
        this._setup_status_text(div_status);

        // CSS adjustments, TODO: check usage
        this._table_details
            .get_dc_chart()
            .on("postRedraw", chart => this._update_cells(chart));
        this._table_details
            .get_dc_chart()
            .on("renderlet", chart => this._update_css_classes(chart));

        // Parameters used for url generation
        this._ifid = null;
        this._vlanid = null;
        this._fetch_filters = {};

        this._div_selection = this._div_selection.classed(
            ntop_utils.ifid_dep,
            true
        );
        this._div_selection.datum(this);
        this.scheduler.enable();
    }

    getEmptyData() {
        return cmk_figures.getEmptyBasicFigureData();
    }

    _convert_alert_details_to_dc_table_format(data) {
        data.alerts.forEach(function (d, i) {
            d.index = i;
            d.date = new Date(1000 * d.date);
        });
        return data.alerts;
    }

    page_id() {
        return "";
    }

    update_post_body() {
        const parameters = this.get_url_search_parameters();
        // The post body of this function is always only responsible for the timeseries graphs
        parameters.append("timeseries_only", "1");
        this._post_body = parameters.toString();
    }

    get_url_search_parameters() {
        const parameters = new URLSearchParams();
        const params = Object.assign(
            {ifid: this._ifid, vlanid: this._vlanid},
            this._fetch_filters,
            this._get_time_filter_params(),
            this.current_ntophost == undefined
                ? {}
                : {host: this.current_ntophost}
        );
        Object.keys(params).forEach(key => {
            parameters.append(key, params[key]);
        });
        return parameters;
    }

    set_ids(ifid, vlanid = "0") {
        this._ifid = ifid;
        this._vlanid = vlanid;
        this.update_post_body();
        this.scheduler.force_update();
    }

    _setup_time_based_filters(selection) {
        // These parameters -may- include activated filters
        this._date_filter = null;
        this._hour_filter = null;
        const table = selection
            .append("table", "div.paging")
            .classed("filter_graphs", true);
        table
            .append("tr")
            .selectAll("td")
            .data(["day", "hour"])
            .join("td")
            .text(d => "Filter by " + d);
        const graphs_row = table.append("tr");
        this._setup_date_filter(graphs_row.append("td"));
        this._setup_hour_filter(graphs_row.append("td"));
    }

    _update_filter_choices(filter_choices) {
        this._filter_choices = filter_choices;
        this._setup_fetch_filters(
            this._div_selection.select("div.fetch_filters." + this.page_id())
        );
    }

    _setup_fetch_filters(selection) {
        const dropdowns = selection
            .selectAll("div.dropdown")
            .data(this._filter_choices)
            .join("div")
            .style("display", "inline-block")
            .classed("dropdown", true);
        dropdowns
            .selectAll("label")
            .data(d => [d])
            .join("label")
            .text(d => d.group);
        const select = dropdowns
            .selectAll("select")
            .data(d => [d])
            .join("select")
            .attr("class", "filter alerts")
            .on("change", event => this._fetch_filters_changed(event));

        select
            .selectAll("option")
            .data(d => d.choices)
            .join(enter =>
                enter
                    .append("option")
                    .property("value", d => "" + d.id)
                    .text(d => d.name)
            );
    }

    _fetch_filters_changed(event) {
        if (event.target == null) return;
        const target = d3.select<HTMLSelectElement, ABCAlertsPage>(
            event.target
        );
        this._fetch_filters = {};
        if (event.target.value != -1)
            this._fetch_filters[target.datum().url_param] = event.target.value;
        this.update_post_body();

        // Reset all other filters
        const selected_index = event.target.selectedIndex;
        this._div_selection
            .selectAll("select.filter.alerts option")
            .property("selected", false);
        event.target.selectedIndex = selected_index;
        this.show_loading_image();
        this._table_details.reset();
        this.scheduler.force_update();
    }

    _setup_date_filter(selection) {
        const div_id = this.page_id() + "_date_filter";
        selection
            .append("div")
            .attr("id", div_id)
            .classed("date_filter", true)
            .style("display", "inline");
        const date_group = this._date_dimension
            .group(d => {
                return d3.timeDay.floor(d);
            })
            .reduceSum(d => d.count);
        const date_chart = dc.barChart("#" + div_id, this.page_id());
        const now = new Date();
        const chart_x_domain = [
            new Date((now.getTime() / 1000 - 31 * 86400) * 1000),
            now,
        ];
        date_chart
            .width(500)
            .height(120)
            .dimension(this._date_dimension)
            .group(date_group)
            .margins({left: 30, top: 5, right: 20, bottom: 20})
            .x(d3.scaleTime().domain(chart_x_domain))
            .xUnits(d3.timeDays)
            .colors(() => {
                return "#767d84c2";
            })
            .elasticY(true)
            .on("postRedraw", () => {
                const filter_params = this._get_time_filter_params();
                this._div_selection
                    .select("div.status")
                    .classed("loading_img_icon", true)
                    .select("label")
                    .text(this._compute_status_text(filter_params));

                const parameters = this.get_url_search_parameters();
                parameters.append("details_only", "1");
                this._table_details.set_post_url_and_body(
                    this._post_url,
                    parameters.toString()
                );
                this._table_details.scheduler.force_update();
            });
        this._date_filter = date_chart.filters;
        date_chart
            .yAxis()
            .ticks(5)
            .tickFormat(d => {
                return number_format.fmt_number_with_precision(
                    d,
                    number_format.SIUnitPrefixes,
                    0
                );
            });
        date_chart
            .xAxis()
            .ticks(5)
            .tickFormat(d => {
                if (d.getMonth() === 0 && d.getDate() === 1)
                    return d3.timeFormat("%Y")(d);
                else if (d.getHours() === 0 && d.getMinutes() === 0)
                    return d3.timeFormat("%m-%d")(d);
                return d3.timeFormat("%H:%M")(d);
            });
        date_chart.render();
    }

    _compute_status_text(filter_params) {
        function _format_date(timestamp, skip_date = false) {
            const date = new Date(timestamp * 1000);
            let response = "";
            if (!skip_date)
                response +=
                    date.getFullYear() +
                    "/" +
                    (date.getMonth() + 1) +
                    "/" +
                    (date.getDay() + 1) +
                    " ";
            response +=
                ("0" + date.getHours()).slice(-2) +
                ":" +
                ("0" + date.getMinutes()).slice(-2);
            return response;
        }
        function _format_absolute_hour(hour_number) {
            const timezoneOffset = new Date().getTimezoneOffset();
            hour_number = parseInt(hour_number) * 60 - timezoneOffset;
            return (
                ("00" + Math.floor(hour_number / 60)).slice(-2) +
                ":" +
                ("00" + (hour_number % 60)).slice(-2)
            );
        }

        let status_text = "Fetching details data";
        if (filter_params.date_start != undefined) {
            status_text +=
                " from " +
                _format_date(filter_params.date_start) +
                " to " +
                _format_date(filter_params.date_end);
        } else if (filter_params.hour_start != undefined) {
            // TODO: Get default timeranges (31 days + 5 hours) from ajax response
            status_text += " from last 31 days";
        } else status_text += " from last 5 hours";

        if (filter_params.hour_start != undefined) {
            status_text +=
                " - Restrict hours from " +
                _format_absolute_hour(filter_params.hour_start) +
                " to " +
                _format_absolute_hour(filter_params.hour_end);
        }

        return status_text;
    }

    _get_time_filter_params() {
        const filter_params = {};
        const hour_filter = this._hour_filter();
        const timezoneOffset = new Date().getTimezoneOffset() / 60;
        if (hour_filter && hour_filter.length == 1) {
            filter_params["hour_start"] = hour_filter[0][0] + timezoneOffset;
            filter_params["hour_end"] = hour_filter[0][1] + timezoneOffset;
        }

        const date_filter: Date[][] = this._date_filter();

        if (Array.isArray(date_filter) && date_filter.length === 1) {
            filter_params["date_start"] = Math.trunc(
                date_filter[0][0].getTime() / 1000
            );
            filter_params["date_end"] = Math.trunc(
                date_filter[0][1].getTime() / 1000
            );
        }
        return filter_params;
    }

    _setup_hour_filter(selection) {
        const div_id = this.page_id() + "_time_filter";
        selection
            .append("div")
            .attr("id", div_id)
            .classed("date_filter", true)
            .style("display", "inline");
        const hour_group = this._hour_dimension
            .group(d => {
                return Math.floor(d);
            })
            .reduceSum(d => d.count);
        const hour_chart = dc
            .barChart("#" + div_id, this.page_id())
            .width(500)
            .height(120)
            .centerBar(true)
            .dimension(this._hour_dimension)
            .group(hour_group)
            .margins({left: 30, top: 5, right: 20, bottom: 20})
            .x(
                d3
                    .scaleLinear()
                    .domain([0, 24])
                    .rangeRound([0, 10 * 24])
            )
            .colors(() => {
                return "#767d84c2";
            })
            .elasticY(true);
        this._hour_filter = hour_chart.filters;
        hour_chart
            .yAxis()
            .ticks(5)
            .tickFormat(d => {
                return number_format.fmt_number_with_precision(
                    d,
                    number_format.SIUnitPrefixes,
                    0
                );
            });
        hour_chart.render();
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    _setup_details_table(selector) {}

    _setup_description_filter(selection) {
        selection.append("label").text("Filter details by description");
        const msg_dimension = this._table_details
            .crossfilter()
            .dimension(d => d.msg);
        selection
            .append("input")
            .attr("type", "text")
            .classed("msg_filter", true)
            .on("input", event => {
                const target = d3.select(event.target);
                const filter = target.property("value");
                msg_dimension.filter(d => {
                    return d.toLowerCase().includes(filter.toLowerCase());
                });
                this._table_details.update_gui();
            });
    }

    _setup_status_text(selection) {
        selection.classed("status", true).append("label");
    }

    _update_css_classes(chart) {
        chart
            .selectAll("tr")
            .selectAll("a")
            .classed("ntop_link", true)
            .attr("target", "_blank");
        chart.select("thead tr").classed("header", true);
    }

    _update_cells(chart) {
        this._update_severity(chart);
        // TODO: deactivated for now
        // make sure to reactivate if this feature gets implemented properly
        //this._update_actions(chart);
    }

    _update_severity(chart) {
        const columns = this._get_columns();
        chart
            .selectAll("tr")
            .selectAll("td")
            .each((d, idx, nodes) => {
                const classes = columns[idx].classes;
                if (!classes) return;
                const node = d3.select(nodes[idx]);
                classes.forEach(classname => {
                    node.classed(classname, true);
                });
            });

        // Add state class to severity
        chart.selectAll("td.severity").each((d, idx, nodes) => {
            const label = d3.select(nodes[idx]).select("label");
            label.classed("badge", true);
            if (d.severity.toLowerCase() == "error")
                label.classed("state2", true);
            else if (d.severity.toLowerCase() == "warning")
                label.classed("state1", true);
        });
    }

    update_data(data) {
        cmk_figures.FigureBase.prototype.update_data.call(this, data);
        const time: {date: Date; count: number}[] = [];
        data.time_series.forEach(entry => {
            time.push({
                date: new Date(entry[0] * 1000),
                count: entry[1],
            });
        });

        // Update filters
        this._update_filter_choices(data.filter_choices);
        this._crossfilter_time.remove(() => true);
        this._crossfilter_time.add(time);
        this._table_details.update_gui();
    }

    update_gui() {
        dc.redrawAll(this.page_id());
    }

    _get_columns() {
        throw new Error("method not implemented");
    }
}

// Base class for all alert tabs
export class ABCAlertsTab extends cmk_tabs.Tab {
    _page_class;
    _alerts_page;
    constructor(tabs_bar) {
        super(tabs_bar);
        this._page_class = null;
        this._tab_selection.classed("ntop_alerts", true);
    }

    initialize() {
        const div_id = this.tab_id() + "_alerts_table";
        this._tab_selection.append("div").attr("id", div_id);
        this._alerts_page = new this._page_class("#" + div_id, this.tab_id());
        this._alerts_page.initialize();
        this._alerts_page.scheduler.set_update_interval(60);
        this._alerts_page.scheduler.enable();
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    activate() {}

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    deactivate() {}
}

//   .-Engaged------------------------------------------------------------.
//   |              _____                                  _              |
//   |             | ____|_ __   __ _  __ _  __ _  ___  __| |             |
//   |             |  _| | '_ \ / _` |/ _` |/ _` |/ _ \/ _` |             |
//   |             | |___| | | | (_| | (_| | (_| |  __/ (_| |             |
//   |             |_____|_| |_|\__, |\__,_|\__, |\___|\__,_|             |
//   |                          |___/       |___/                         |
//   +--------------------------------------------------------------------+
export class EngagedAlertsTab extends ABCAlertsTab {
    constructor(tabs_bar) {
        super(tabs_bar);
        this._page_class = EngagedAlertsPage;
    }

    tab_id() {
        return "engaged_alerts_tab";
    }

    name() {
        return "Engaged";
    }
}

class EngagedAlertsPage extends ABCAlertsPage {
    constructor(div_selector) {
        super(div_selector);
        this._post_url = "ajax_ntop_engaged_alerts.py";
    }

    page_id() {
        return "engaged_alerts";
    }

    update_post_body() {
        const parameters = this.get_url_search_parameters();
        parameters.append("details_only", "1");
        this._post_body = parameters.toString();
    }

    initialize(with_debugging) {
        super.initialize(with_debugging);
        this.subscribe_data_pre_processor_hook(data => {
            const days = {};
            const timeseries_data: [number, number][] = [];
            data.alerts.forEach(alert => {
                if (this._filter_entity(alert.entity_val)) return;
                const start_timestamp = alert.date;
                days[start_timestamp] = (days[start_timestamp] || 0) + 1;
            });
            for (const start_timestamp in days) {
                timeseries_data.push([
                    parseInt(start_timestamp),
                    days[start_timestamp],
                ]);
            }
            return {
                time_series: timeseries_data,
                filter_choices: data.filter_choices,
                ntop_link: data.ntop_link,
            };
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _filter_entity(entity_val) {
        return false;
    }

    _get_columns() {
        return [
            {
                label: "Date",
                format: d => {
                    return (
                        d.date.toLocaleDateString("de") +
                        " " +
                        d.date.toLocaleTimeString("de")
                    );
                },
                classes: ["date", "number"],
            },
            {
                label: "Duration",
                format: d => {
                    return ntop_utils.seconds_to_time(d.duration);
                },
                classes: ["duration", "number"],
            },
            {
                label: "Severity",
                format: d => {
                    return "<label>" + d.severity + "</label>";
                },
                classes: ["severity"],
            },
            {
                label: "Alert type",
                format: d => d.type,
                classes: ["alert_type"],
            },
            // TODO: deactivated for now
            // make sure to reactivate if this feature gets implemented properly
            //{
            //    label: "Drilldown",
            //    format: ()=>{return "Drilldown Link";},
            //    classes: ["drilldown"]
            //},
            {
                label: "Description",
                format: d => d.msg,
                classes: ["description"],
            },
            // TODO: deactivated for now
            // make sure to reactivate if this feature gets implemented properly
            //{
            //    label: "Actions",
            //    format: ()=>"",
            //    classes: ["actions"]
            //},
        ];
    }
}

//   .-Past---------------------------------------------------------------.
//   |                         ____           _                           |
//   |                        |  _ \ __ _ ___| |_                         |
//   |                        | |_) / _` / __| __|                        |
//   |                        |  __/ (_| \__ \ |_                         |
//   |                        |_|   \__,_|___/\__|                        |
//   |                                                                    |
//   +--------------------------------------------------------------------+
export class PastAlertsTab extends ABCAlertsTab {
    constructor(tabs_bar) {
        super(tabs_bar);
        this._page_class = PastAlertsPage;
    }

    tab_id() {
        return "past_alerts_tab";
    }

    name() {
        return "Past";
    }
}

class PastAlertsPage extends ABCAlertsPage {
    constructor(div_selector) {
        super(div_selector);
        this._post_url = "ajax_ntop_past_alerts.py";
    }

    page_id() {
        return "past_alerts";
    }

    _get_columns() {
        return [
            {
                label: "Date",
                format: d => {
                    return (
                        d.date.toLocaleDateString("de") +
                        " " +
                        d.date.toLocaleTimeString("de")
                    );
                },
                classes: ["date", "number"],
            },
            {
                label: "Duration",
                format: d => {
                    return ntop_utils.seconds_to_time(d.duration);
                },
                classes: ["duration", "number"],
            },
            {
                label: "Severity",
                format: d => {
                    return "<label>" + d.severity + "</label>";
                },
                classes: ["severity"],
            },
            {
                label: "Alert type",
                format: d => d.type,
                classes: ["alert_type"],
            },
            // TODO: deactivated for now
            // make sure to reactivate if this feature gets implemented properly
            //{
            //    label: "Drilldown",
            //    format: ()=>{
            //        return "<img src=themes/facelift/images/icon_zoom.png>";
            //    },
            //    classes: ["drilldown"]
            //},
            {
                label: "Description",
                format: d => d.msg,
                classes: ["description"],
            },
            // TODO: deactivated for now
            // make sure to reactivate if this feature gets implemented properly
            //{
            //    label: "Actions",
            //    format: ()=>"",
            //    classes: ["actions"]
            //},
        ];
    }
}

//   .-Flows--------------------------------------------------------------.
//   |                      _____ _                                       |
//   |                     |  ___| | _____      _____                     |
//   |                     | |_  | |/ _ \ \ /\ / / __|                    |
//   |                     |  _| | | (_) \ V  V /\__ \                    |
//   |                     |_|   |_|\___/ \_/\_/ |___/                    |
//   |                                                                    |
//   +--------------------------------------------------------------------+

export class FlowAlertsTab extends ABCAlertsTab {
    constructor(tabs_bar) {
        super(tabs_bar);
        this._page_class = FlowAlertsPage;
    }

    tab_id() {
        return "flow_alerts_tab";
    }

    name() {
        return "Flows";
    }
}

class FlowAlertsPage extends ABCAlertsPage {
    constructor(div_selector) {
        super(div_selector);
        this._post_url = "ajax_ntop_flow_alerts.py";
    }

    page_id() {
        return "flow_alerts";
    }

    _get_columns() {
        return [
            {
                label: "Date",
                format: d => {
                    return (
                        d.date.toLocaleDateString("de") +
                        " " +
                        d.date.toLocaleTimeString("de")
                    );
                },
                classes: ["date", "number"],
            },
            {
                label: "Severity",
                format: d => {
                    return "<label>" + d.severity + "</label>";
                },
                classes: ["severity"],
            },
            {
                label: "Alert type",
                format: d => d.type,
                classes: ["alert_type"],
            },
            {
                label: "Score",
                format: d => d.score,
                classes: ["score", "number"],
            },
            {
                label: "Description",
                format: d => d.msg,
                classes: ["description"],
            },
            // TODO: deactivated for now
            // make sure to reactivate if this feature gets implemented properly
            //{
            //    label: "Actions",
            //    format: ()=>"",
            //    classes: ["actions"]
            //},
        ];
    }
}
